import React from "react";
import img from "../../assets/image/logo.png";
import BTC from "cryptocurrency-icons/svg/color/btc.svg";
import ETH from "cryptocurrency-icons/svg/color/eth.svg";
import LTC from "cryptocurrency-icons/svg/color/ltc.svg";

const Border = () => {
    return (
        <div className="py-10 background-color-gray" id="order">
            <div className="container">
                <div className="flex items-center justify-between flex-col md:flex-row gap-5">
                    <div className="flex-[2]">
                        <h2 className="text-brand text-[35px] sm:text-[70px] font-poppins font-semibold">
                            ORDER NOW
                        </h2>
                        <a
                            href="https://app.v10proxies.com/"
                            className="inline-block py-2 px-5 w-full sm:w-[450px] font-poppins text-[18px] sm:text-[25px] hover:bg-gradient-to-t from-brand/90 via-brand/20 duration-300 transition-all hover:shadow-4xl  font-semibold bg-brand-button bg-opacity-40 rounded-xl"
                        >
                            Open our dashboard
                        </a>
                        <span className="block text-[20px] font-poppins font-normal my-2">
              We accept
            </span>
                        <div className="flex items-center gap-3">
                            <div className="flex items-center">
                                <img src={BTC} className="h-[30px]"></img>
                                <h3 className="text-[30px] font-poppins font-semibold">
                                    <i>Bitcoin</i>
                                </h3>
                            </div>
                        </div>
                        <div className="flex items-center gap-3">
                            <div className="flex items-center">
                                <img src={LTC} className="h-[30px]"></img>
                                <h3 className="text-[30px] font-poppins font-semibold">
                                    <i>Litecoin</i>
                                </h3>
                            </div>
                        </div>
                        <div className="flex items-center gap-3">
                            <div className="flex items-center">
                                <img src={ETH} className="h-[30px]"></img>
                                <h3 className="text-[30px] font-poppins font-semibold">
                                    <i>Ethereum</i>
                                </h3>
                            </div>
                        </div>
                        <div className="flex items-center gap-3">
                            <div className="flex items-center">
                                <h3 className="text-[30px] font-poppins font-semibold">
                                    <i>and Others...</i>
                                </h3>
                            </div>
                        </div>
                        <p className="text-[20px] color-red font-poppins font-normal mt-28">
                            Check out our discord!
                        </p>
                        <a
                            href="https://discord.v10proxies.com/"
                            className=" inline-block my-5 py-2 px-5 cursor-pointer   font-poppins text-[18px] sm:text-[25px] hover:bg-gradient-to-t from-brand/90 via-brand/20 duration-300 transition-all hover:shadow-4xl  font-semibold bg-brand-button bg-opacity-40 rounded-xl"
                        >
                            Discord
                        </a>
                    </div>
                    <div className="flex-[3] flex items-center justify-center">
                        <img
                            src={img}
                            alt=""
                            className="w-[500px] h-[300px] md:h-[500px]"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Border;
