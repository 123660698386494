import React from "react";
import Border from "../components/homeComponent/Border";
import Hero from "../components/homeComponent/Hero";
import Market from "../components/homeComponent/Market";
import Navbar from "../components/homeComponent/Navbar";
import Products from "../components/homeComponent/Products";

const Home = () => {
  return (
    <div className=" bg-brand-bg text-white" id="home">
      <Navbar />
      <Hero />
      <Market />
      <Products />
      <Border />
    </div>
  );
};

export default Home;
