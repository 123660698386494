import React from "react";
import { AiOutlineHome } from "react-icons/ai";
import {
  AiOutlineDashboard,
  AiOutlineUndo,
  AiOutlineFileProtect,
} from "react-icons/ai";
import { SiAiohttp } from "react-icons/si";
import { GiHumanTarget } from "react-icons/gi";
import { RxGlobe, RxLapTimer, RxSketchLogo } from "react-icons/rx";
import img from "../../assets/image/logo.png";
import dashboard from "../../assets/image/dashboard.png";

const Market = () => {
  const data = [
    {
      title: "Unlimited Threads",
      icon: <AiOutlineHome />,
    },
    {
      title: "Unlimited Bandwidth",
      icon: <AiOutlineUndo />,
    },
    {
      title: "HTTP Protocol",
      icon: <SiAiohttp />,
    },
    {
      title: "50Gbps infrastructure",
      icon: <AiOutlineDashboard />,
    },
    {
      title: "Account Checking",
      icon: <AiOutlineFileProtect />,
    },
    {
      title: "Unlimited Bandwidth",
      icon: <GiHumanTarget />,
    },
    {
      title: "Generation Focused",
      icon: <RxGlobe />,
    },
    {
      title: "Over 1 Octilion IP Pool",
      icon: <RxLapTimer />,
    },
    {
      title: "Many IPv4 targets",
      icon: <RxSketchLogo />,
    },
  ];

  return (
    <div className="py-20 background-color-gray " id="about">
      <div className="container">
        <div>
          <h2 className="text-[25px] text-center md:text-left md:text-[60px] font-poppins font-semibold ">
            THE{" "}
            <span className=" text-transparent bg-clip-text bg-gradient-to-r from-brand-red  to-brand ">
              BEST PROXIES
            </span>
          </h2>
          <h2 className="text-[25px] text-center md:text-left md:text-[60px] font-poppins font-semibold ">
            ON THE MARKET
          </h2>

          <div className="flex items-center justify-between mt-7 flex-col md:flex-row gap-2">
            <div className="flex-[2] flex items-start gap-3 justify-between flex-col">
              {data.map((item, i) => {
                return (
                  <button
                    key={i}
                    className="py-3 px-10 bg-brand-button bg-opacity-60 grayscale text-gray-500 hover:text-white hover:grayscale-0 hover:bg-opacity-90 duration-300 transition-all hover:shadow-4xl w-[300px] flex items-center justify-start rounded-xl text-[15px] font-poppins font-semibold gap-3"
                  >
                    <span className=" text-brand text-[20px]">{item.icon}</span>
                    {item.title}
                  </button>
                );
              })}
            </div>
            <div className="flex-[4] w-full md:w-[500px] h-[500px] bg-slate-500 rounded-2xl flex items-center justify-center" style={{backgroundColor: "#101010"}}>
              <img src={dashboard} alt="" style={{borderRadius: "25px", width:"90%", height: "auto"}} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Market;
