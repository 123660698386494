import React from "react";
import img from "../../assets/image/logo.png";

const Products = () => {
  const data = [
    {
      img: img,
      price: "4.00",
      time: "1 Hour",
      p1: "Unlimited Threads",
      p2: "Unlimited Bandwidth",
      p3: "HTTP Protocol",
      p4: "50Gbps Infrastructure.",
      button: "Buy Now",
    },
    {
      img: img,
      price: "20.00",
      time: "1 Day",
      p1: "Unlimited Threads",
      p2: "Unlimited Bandwidth",
      p3: "HTTP Protocol",
      p4: "50Gbps Infrastructure.",
      button: "Buy Now",
    },
    {
      img: img,
      price: "130.00",
      time: "1 Week",
      p1: "Unlimited Threads",
      p2: "Unlimited Bandwidth",
      p3: "HTTP Protocol",
      p4: "50Gbps Infrastructure.",
      button: "Buy Now",
    },
    {
      img: img,
      price: "500.00",
      time: "1 Month",
      p1: "Unlimited Threads",
      p2: "Unlimited Bandwidth",
      p3: "HTTP Protocol",
      p4: "50Gbps Infrastructure.",
      button: "Buy Now",
    },
  ];

  return (
    <div className="py-12" id="products">
      <div className="container">
        <div>
          <div className=" text-center text-[30px] md:text-[71px] font-poppins font-semibold mb-5">
            <h2>
              Our{" "}
              <span className=" text-transparent bg-clip-text bg-gradient-to-r from-brand-red  to-brand ">
                BLAZING FAST
              </span>
            </h2>
            <h2>PRODUCTS</h2>
          </div>

          <div className=" grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 items-center gap-6">
            {data.map((item, i) => {
              return (
                <div
                  key={i}
                  className=" group bg-brand-button bg-opacity-10 text-center py-4 rounded-xl hover:bg-gradient-to-t from-brand/90 via-brand/20 duration-300 transition-all cursor-pointer to-brand-bg hover:shadow-4xl"
                >
                  <div className="flex items-center justify-center">
                    <img
                      src={item.img}
                      alt=""
                      className="w-[120px] h-[120px] grayscale group-hover:grayscale-0 group-hover:slots-container"
                    />
                  </div>
                  <div className=" font-poppins text-gray-600 group-hover:text-white">
                    <h2 className="text-[40px] font-semibold font-poppins">
                      <span>$</span>
                      {item.price}
                    </h2>
                    <span className=" font-poppins font-bold text-[18px]">
                      {item.time}
                    </span>
                    <p className="text-[14px] py-[8px]">{item.p1}</p>
                    <p className="text-[14px] py-[8px]">{item.p2}</p>
                    <p className="text-[14px] py-[8px]">{item.p3}</p>
                    <p className="text-[14px] py-[8px]">{item.p4}</p>
                    <p className="text-[14px] py-[10px]"></p>
                    <a

                        href="https://app.v10proxies.com/"
                        className="my-3 py-2 px-10 rounded-xl  border-4  border-gray-600 group-hover:border-y-[#E74622] group-hover:border-x-brand font-poppins font-bold text-[18px]">
                      {item.button}
                    </a>
                    <p className="text-[14px] py-[10px]"></p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Products;
