import { useState } from "react";
import logo2 from "../../assets/image/logowithtext.png";

const Navbar = () => {
  const [isOpen, setisOpen] = useState(false);
  return (
    <nav className=" px-2 sm:px-4 py-2.5 rounded background-color-gray">
      <div className="container flex flex-wrap items-center justify-between mx-auto">
        <div className="flex gap-5">
          <a href="#home" className="flex items-center">
            <img src={logo2} className="h-10 mr-3 sm:h-20" alt="Logo" />
          </a>
          <div
            className="items-center justify-between hidden w-full md:flex md:w-auto md:order-1"
            id="mobile-menu-2"
          >
            <ul className="flex flex-col p-4 mt-4 md:flex-row md:space-x-8 md:mt-0 md:text-sm md:font-medium md:border-0 ">
              <li>
                <a
                  href="#home"
                  className="block py-2 pl-3 pr-4 text-white md:bg-transparent md:p-0 font-bold"
                >
                  Home
                </a>
              </li>
              <li>
                <a
                  href="#about"
                  className="block py-2 pl-3 pr-4 text-white md:bg-transparent md:p-0 font-bold"
                >
                  About
                </a>
              </li>
              <li>
                <a
                  href="#products"
                  className="block py-2 pl-3 pr-4 text-white md:bg-transparent md:p-0 font-bold"
                >
                  Products
                </a>
              </li>
              <li>
                <a
                  href="#products"
                  className="block py-2 pl-3 pr-4 text-white md:bg-transparent md:p-0 font-bold"
                >
                  Pricing
                </a>
              </li>
              <li>
                <a
                  href="#order"
                  className="block py-2 pl-3 pr-4 text-white md:bg-transparent md:p-0 font-bold"
                >
                  Contact
                </a>
              </li>
            </ul>
          </div>
        </div>

        <div className="flex items-center md:order-2 relative">
          {/* Dropdown menu */}

          {isOpen && (
            <div className="z-[9999] absolute top-8 right-0 my-4 text-base list-none bg-gray-800 border border-gray-700 rounded-xl shadow">
              <ul className="py-1">
                <li>
                  <a
                    href="#home"
                    className="block px-4 py-2 text-base text-white hover:bg-gray-600 "
                  >
                    Home
                  </a>
                </li>
                <li>
                  <a
                    href="#about"
                    className="block px-4 py-2 text-base text-white hover:bg-gray-600 "
                  >
                    About
                  </a>
                </li>
                <li>
                  <a
                    href="#products"
                    className="block px-4 py-2 text-base text-white hover:bg-gray-600 "
                  >
                    Products
                  </a>
                </li>
                <li>
                  <a
                    href="#products"
                    className="block px-4 py-2 text-base text-white hover:bg-gray-600 "
                  >
                    Pricing
                  </a>
                </li>
                <li>
                  <a
                    href="#order"
                    className="block px-4 py-2 text-base text-white hover:bg-gray-600 "
                  >
                    Contact
                  </a>
                </li>
                <li>
                  <a
                    href="https://app.v10proxies.com/"
                    className="block px-4 py-2 text-base text-white hover:bg-gray-600 "
                  >
                    APP
                  </a>
                </li>
              </ul>
            </div>
          )}
          <button
            className="inline-flex items-center p-2 ml-1 text-sm text-brand rounded-lg md:hidden hover:bg-gray-700 focus:outline-none"
            onClick={() => setisOpen(!isOpen)}
          >
            <span className="sr-only">Open main menu</span>
            <svg
              className="w-6 h-6"
              aria-hidden="true"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                clipRule="evenodd"
              />
            </svg>
          </button>
        </div>

        <div className="hidden lg:block space-x-3 ml-auto">
          <a
            href="https://app.v10proxies.com/"
            className="bg-brand border border-brand px-8 py-2 rounded-xl font-bold"
          >
            APP
          </a>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
