import React from "react";
import logo from "../../assets/image/logo.png";

const Hero = () => {
  return (
    <div>
      <div className="container">
        <div className="flex items-center justify-center flex-col py-20">
          <img className="w-[200px] h-[200px]" src={logo} alt="" />
          <h2 className="text-[30px] md:text-[80px] text-brand font-poppins font-bold">
            V10 PROXIES
          </h2>
          <p className="text-[18px] text-center md:text-[30px] font-poppins font-semibold">
            IPV6 PROXIES BYPASSED FOR IPV4 WEBSITES
          </p>
          <a

              href="https://app.v10proxies.com/"
              className=" py-2 px-10 text-[20px] font-poppins font-semibold text-white hover:border-x-brand  hover:bg-gradient-to-t from-brand/90 via-brand/20 duration-300 transition-all  border-none rounded-xl mt-3 bg-brand-button bg-opacity-50"
          >
            Buy Now
          </a>
        </div>
      </div>
    </div>
  );
};

export default Hero;
